import * as React from 'react';

import cx from 'classnames';
import footer from 'components/lexemes/footer';
import LocaleContext from 'lib/locale-context';
import { socialLinks } from 'lib/social-links';

import AppLinks from 'components/app-links/app-links';
import Link from 'components/link/link';
import SocialLinks from 'components/social-links/social-links';

type Props = {
    items?: {
        isActive?: boolean;
        headerDropDownMenu?: {
            getUrl(lang: string): string;
            title?: string | React.ReactNode;
            onClick?: () => void;
        }[];
        url?: string;
        title?: string | React.ReactNode;
        onClick?: () => void;
    }[];
    id?: string;
    onAppleStoreClick?: () => void;
    onGoogleStoreClick?: () => void;
    ariaLabel: string;
};

function HeaderMobileMenu({ items, id, onAppleStoreClick, onGoogleStoreClick, ariaLabel }: Props) {
    const lang = React.useContext(LocaleContext);
    return (
        <nav className="header-mobile-menu" id={id} aria-label={ariaLabel}>
            <div className="header-mobile-menu__inner">
                <ul className="header-mobile-menu__list" role="list">
                    {items &&
                        items.map((item, index) => {
                            const { isActive, headerDropDownMenu, url, title } = item;
                            const className = cx({
                                'header-mobile-menu__item': true,
                                'is-active': isActive,
                            });

                            return (
                                <li className={className} key={`header-mobile-menu-item-${index}`}>
                                    <Link
                                        className="header-mobile-menu__link"
                                        href={url}
                                        onClick={item.onClick}
                                        aria-current={isActive ? 'page' : undefined}
                                    >
                                        {title}
                                    </Link>

                                    {headerDropDownMenu ? (
                                        <ul className="header-mobile-menu__sublist" role="list">
                                            {headerDropDownMenu &&
                                                headerDropDownMenu.map(
                                                    (menuItem, dropdownIndex) => {
                                                        const {
                                                            getUrl,
                                                            title: headerTitle,
                                                            onClick,
                                                        } = menuItem;

                                                        return (
                                                            <li
                                                                className="header-mobile-menu__item"
                                                                key={`header-mobile-menu--dropdown-item-${dropdownIndex}`}
                                                            >
                                                                <Link
                                                                    className="header-mobile-menu__link"
                                                                    href={getUrl(lang)}
                                                                    onClick={onClick}
                                                                >
                                                                    {headerTitle}
                                                                </Link>
                                                            </li>
                                                        );
                                                    },
                                                )}
                                        </ul>
                                    ) : null}
                                </li>
                            );
                        })}
                </ul>

                <div className="header-mobile-menu__social-links">
                    <SocialLinks items={socialLinks} ariaLabel={footer.lxFollowUsTitle()} />
                </div>

                <div className="header-mobile-menu__footer">
                    <AppLinks
                        onAppleStoreClick={onAppleStoreClick}
                        onGoogleStoreClick={onGoogleStoreClick}
                        description={footer.lxDownloadCopyText()}
                    />
                </div>
            </div>
        </nav>
    );
}

export default HeaderMobileMenu;
