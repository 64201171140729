import React, { FunctionComponent } from 'react';

import { header, common, footer } from 'components/lexemes';
import { useGlobalProps } from 'lib/hooks/use-global-props';
import { signInAction } from 'lib/seo/seo';

import {
    onAppleStoreClick,
    onGoogleStoreClick,
    onMenuItemClickHandler,
    onClickHeaderOpenMenuCta,
} from 'containers/events';

import Header from 'components/header/header';
import HeaderMenu, { HeaderMenuProps } from 'components/header/header-menu';
import HeaderMobileMenu from 'components/header/header-mobile-menu';
import Logo from 'components/logo/logo';
import SkipLink from 'components/skip-link/skip-link';

// TODO: better detection of active page
function setActivePages(pages: HeaderMenuProps['items'] = [], currentUrl: string) {
    pages.forEach((page) => {
        // Check if the current URL starts with the page URL
        if (page.url && currentUrl.startsWith(page.url)) {
            // Ensure that the match is exact or followed by a '/'
            const nextChar = currentUrl[page.url.length];
            if (!nextChar || nextChar === '/') {
                page.isActive = true;
            } else {
                page.isActive = false;
            }
        } else {
            page.isActive = false;
        }

        // Recursively check headerDropDownMenu if it exists
        if (page.headerDropDownMenu && page.headerDropDownMenu.length > 0) {
            setActivePages(page.headerDropDownMenu, currentUrl);
            // If any dropdown item is active, mark the parent as active too
            page.isActive = page.headerDropDownMenu.some((subPage) => subPage.isActive);
        }
    });
}

export const HeaderContainer: FunctionComponent = () => {
    const globalProps = useGlobalProps();
    const lang = globalProps?.lang;

    const signinCta = {
        title: footer.lxFooterItemLabelSignIn(),
        url: `${signInAction}&utm_content=header`,
        onClick: () => onMenuItemClickHandler('sign-in'),
    };

    const headerMenuItems: HeaderMenuProps['items'] = [
        {
            title: header.lxHeaderItemLabelShop,
            url: 'https://bumble.shop/',
            onClick: () => onMenuItemClickHandler('shop'),
        },
        {
            title: 'The App',
            headerDropDownMenu: [
                {
                    title: header.lxHeaderItemLabelAppModeDate,
                    getUrl() {
                        return `https://bumble.com/${lang}/date`;
                    },
                    onClick: () => onMenuItemClickHandler('date'),
                },
                {
                    title: header.lxHeaderItemLabelAppModeBFF,
                    getUrl() {
                        return `https://bumble.com/${lang}/bff`;
                    },
                    onClick: () => onMenuItemClickHandler('bff'),
                },
                {
                    title: header.lxHeaderItemLabelAppModeBizz,
                    getUrl() {
                        return `https://bumble.com/${lang}/bizz`;
                    },
                    onClick: () => onMenuItemClickHandler('bizz'),
                },
            ],
        },
        {
            title: header.lxHeaderItemLabelAmbassadors,
            url: 'https://bumble.com/bumble-honey-ambassadors',
            onClick: () => onMenuItemClickHandler('ambassadors'),
        },
        {
            title: header.lxHeaderItemLabelEvents,
            url: 'https://bumble.events/',
            onClick: () => onMenuItemClickHandler('events'),
        },
        {
            title: header.lxHeaderItemLabelBuzz,
            url: 'https://bumble-buzz.com/',
            onClick: () => onMenuItemClickHandler('blog'),
        },
        {
            title: header.lxHeaderItemLabelAbout,
            url: `https://bumble.com/${lang}/about`,
            onClick: () => onMenuItemClickHandler('about'),
        },
    ];

    if (globalProps.signInCtaAbTest) {
        headerMenuItems.push(signinCta);
    }

    React.useEffect(() => {
        setActivePages(headerMenuItems, window.location.href);
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <SkipLink />
            <Header
                logo={<Logo a11yLabel={common.lxA11yLogo} />}
                headerMenu={<HeaderMenu items={headerMenuItems} />}
                headerMobileMenu={
                    <HeaderMobileMenu
                        items={headerMenuItems}
                        id="mobile-menu"
                        ariaLabel={header.lxA11yNavigationMain}
                        onGoogleStoreClick={onGoogleStoreClick}
                        onAppleStoreClick={onAppleStoreClick}
                    />
                }
                hasMobileMenuToggle={true}
                onClickOpenMenu={onClickHeaderOpenMenuCta}
            />
        </>
    );
};
